import React from 'react';

import Layout from '../../components/Layout';
import EventRoll from '../../components/EventRoll';

export default class EventsIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section id="hero" className="hero is-green">
          <div className="hero-body">
            <div className="container">
              <h1 className="title is-1 has-text-white">
                Owl Path Classes, Workshops, and Trainings
              </h1>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <EventRoll />
          </div>
        </section>
      </Layout>
    );
  }
}
